import { Card, CardBody, Typography } from "@material-tailwind/react";
import React from "react";

function Analytics() {
  return (
    <Card className="p-4 md:p-6">
      <Typography variant="h5" color="black">
        Analytics
      </Typography>
      <Typography variant="small" className="opacity-70">
        Access and view analytics.
      </Typography>
      <CardBody>
        <Typography>
          Reports and detailed documentation available here.
        </Typography>
      </CardBody>
    </Card>
  );
}

export default Analytics;
