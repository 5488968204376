import React, { useState } from "react";
import { Breadcrumbs } from "@material-tailwind/react";
import { Link, Outlet, useLocation } from "react-router-dom";
import {
  HomeIcon,
  AnalyticsIcon,
  ReportsIcon,
  MenuIcon,
  CollapseArrowIcon,
  LogoutIcon,
} from "../../components/NavIcons";

export default function Dashboard() {
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const location = useLocation();

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    window.location.reload();
  };

  return (
    <div className="flex min-h-screen w-full">
      <aside
        className={`transition-all duration-300 ease-in-out bg-navColor text-navTextColor ${
          sidebarExpanded ? "w-60" : "w-20"
        } bg-background border-r md:block`}
      >
        <div
          className={`flex items-center h-16 border-b px-4 ${
            !sidebarExpanded ? "items-center justify-center" : "justify-between"
          } `}
        >
          {sidebarExpanded && (
            <span className="font-bold truncate text-white">Dashboard</span>
          )}

          {sidebarExpanded ? (
            <CollapseArrowIcon
              className="cursor-pointer text-white"
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
            />
          ) : (
            <MenuIcon
              className="cursor-pointer text-white"
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
            />
          )}
        </div>
        <nav
          className={`flex flex-col space-y-1 px-2 py-4 gap-2 ${
            !sidebarExpanded && "items-center justify-center"
          }`}
        >
          <Link
            to="home"
            className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors ${
              location.pathname.includes("home")
                ? "border border-white text-navSelectedColor"
                : "hover:bg-muted hover:text-foreground"
            } ${!sidebarExpanded && "max-w-fit"}`}
          >
            <HomeIcon className="h-5 w-5" />
            {sidebarExpanded && "Home"}
          </Link>
          {/* Commented for now */}
          {/* <Link
            to="analytics"
            className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors ${
              location.pathname.includes("analytics")
                ? "border border-white text-navSelectedColor"
                : "hover:bg-muted hover:text-foreground"
            } ${!sidebarExpanded && "max-w-fit"}`}
          >
            <AnalyticsIcon className="h-5 w-5" />
            {sidebarExpanded && "Analytics"}
          </Link>
          <Link
            to="reports"
            className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors ${
              location.pathname.includes("reports")
                ? "border border-white text-navSelectedColor"
                : "hover:bg-muted hover:text-foreground"
            } ${!sidebarExpanded && "max-w-fit"}`}
          >
            <ReportsIcon className="h-5 w-5" />
            {sidebarExpanded && "Reports"}
          </Link> */}
          {/* Commented for now */}
          <button
            onClick={handleLogout}
            className={`flex items-center gap-2 rounded-md px-3 py-2 text-sm font-medium transition-colors hover:bg-muted hover:text-foreground ${
              !sidebarExpanded && "max-w-fit"
            }`}
          >
            <LogoutIcon className="h-5 w-5" />
            {sidebarExpanded && "Logout"}
          </button>
        </nav>
      </aside>
      <div className="flex-1 flex flex-col max-h-screen">
        <header className="sticky top-0 z-30 border-b">
          <div className="flex h-16 items-center justify-between px-4">
            <div className="flex-1 text-sm font-medium">
              <Breadcrumbs>
                <span>Dashboard</span>
                <span className="opacity-100">
                  {location.pathname
                    .split("/")
                    .slice(-1)[0]
                    .replace(/^\w/, (c) => c.toUpperCase())}
                </span>
              </Breadcrumbs>
            </div>
          </div>
        </header>
        <main className="flex-1 overflow-y-auto p-4 md:p-6 bg-mainContentColor">
          <div className="grid gap-6">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}
