import React, { useContext, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { Button, Input } from "@material-tailwind/react";
import { AuthContext } from "../../Context/AuthContext";
import { notify } from "../../utils/notify";

export default function Login() {
  const userContext = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login, isAuthenticated } = userContext;
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isLogged = await login(email, password);

    if (isLogged) {
      setLoading(false);
      navigate("/dashboard/home");
      notify("success", "Successfully logged in");
    }
    setLoading(false);
  };

  if (isAuthenticated) {
    return <Navigate to="/dashboard" />;
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-1/3 p-8 bg-white rounded-lg shadow-xl">
        <form onSubmit={handleSubmit}>
          <h1 className="text-3xl font-bold text-gray-700 mb-6">Sign In</h1>
          <div className="mb-4">
            <Input
              label="Username"
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="standard"
              color="gray"
              size="lg"
              fullWidth
              required
            />
          </div>
          <div className="mb-4">
            <Input
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="standard"
              color="gray"
              size="lg"
              fullWidth
              required
            />
          </div>

          <Button
            type="submit"
            color="gray"
            ripple="light"
            fullWidth
            className="flex justify-center items-center bg-navColor"
            loading={loading}
          >
            {loading ? "Logging in" : "Log In"}
          </Button>
        </form>
      </div>
    </div>
  );
}
