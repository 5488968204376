import { Card, CardBody, Typography } from "@material-tailwind/react";

export function Reports() {
  return (
    <Card className="p-4 md:p-6">
      <Typography variant="h5" color="black">
        Reports
      </Typography>
      <Typography variant="small" className="opacity-70">
        Access and generate reports.
      </Typography>
      <CardBody>
        <Typography>
          Reports and detailed documentation available here.
        </Typography>
      </CardBody>
    </Card>
  );
}
