import React, { createContext, useContext, useState, useEffect } from "react";
import { Login } from "../services/Auth";
import { notify } from "../utils/notify";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    // Check for token in local storage on component mount
    const token = localStorage.getItem("authToken");
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const login = async (email, password) => {
    try {
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // const res = await Login({ username: email, password, passphrase });
      // if (res.success === true) {
      //   setIsAuthenticated(true);
      //   localStorage.setItem("authToken", res.message);
      //   return true;
      // }

      const hardcodedUsername = "atlas";
      const hardcodedPassword = "@7SX737jB_";

      if (email === hardcodedUsername && password === hardcodedPassword) {
        setIsAuthenticated(true);
        localStorage.setItem("authToken", "testToken");
        return true;
      } else {
        notify("error", "Invalid credentials");
        return false;
      }
    } catch (error) {
      notify("error", error.message);
      return false;
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("authToken");
  };

  return (
    <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
