import axiosInstance from "../../api/api";
import { notify } from "../../utils/notify";

export const getVendors = async () => {
  try {
    const config = {
      method: "get",
      url: `/vendors`,
    };
    const response = await axiosInstance(config);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error(response || "get vendors failed");
    }
  } catch (error) {
    throw error;
  }
};

export const getPurchaseOrders = async (id) => {
  try {
    const config = {
      method: "get",
      url: `/purchase-orders/${id}`,
    };
    const response = await axiosInstance(config);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error(response);
    }
  } catch (error) {
    notify("error", error?.message ? error?.message : error?.data?.message);
    throw error;
  }
};

export const processPdf = async (data) => {
  try {
    const config = {
      method: "post",
      url: `/process`,
      data: data,
    };
    const response = await axiosInstance(config);

    if (response.status === 200 && response.data) {
      return response.data;
    } else {
      throw new Error(response?.data || "post pdf failed");
    }
  } catch (error) {
    notify("error", error.detail);
    throw error;
  }
};
export const pushToZoho = async (data) => {
  try {
    const config = {
      method: "post",
      url: `/zoho/sync`,
      data: data,
    };
    const response = await axiosInstance(config);

    if (response.status === 200 && response.data) {
      return response.data;
    } else if (response.status === 409) {
      // Handle 409 status code with a warning
      notify(
        "warning",
        response?.data?.detail || "Conflict occurred during sync to Zoho"
      );
      throw new Error("handled");
    } else {
      throw new Error(response?.data || "Sync to Zoho failed");
    }
  } catch (error) {
    if (error !== "handled") {
      notify("error", error?.detail || error.message);
    }
    throw error;
  }
};
