import { toast } from "react-toastify";

const customId = "custom-id-yes";

const notify = (type, message) => {
  switch (type) {
    case "success":
      return toast.success(message, {
        position: "top-right",
        autoClose: 2000,
        closeOnClick: true,
        hideProgressBar: true,
        closeButton: true,
        toastId: customId,
        theme: "colored",
      });
    case "error":
      return toast.error(message, {
        position: "top-right",
        autoClose: 2000,
        closeOnClick: true,
        hideProgressBar: true,
        closeButton: true,
        toastId: customId,
        theme: "colored",
      });
    case "warning":
      return toast.warn(message, {
        position: "top-right",
        autoClose: 2000,
        closeOnClick: true,
        hideProgressBar: true,
        closeButton: true,
        toastId: customId,
        theme: "colored",
      });
    default:
      return toast(message, {
        position: "top-center",
        autoClose: 2000,
        closeOnClick: true,
        hideProgressBar: true,
        closeButton: false,
        toastId: customId,
        theme: "colored",
      });
  }
};
export { notify };
