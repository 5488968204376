import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Typography,
  Button,
  Spinner,
  Switch,
} from "@material-tailwind/react";
import { useDropzone } from "react-dropzone";
import { Viewer } from "@react-pdf-viewer/core";
import {
  getPurchaseOrders,
  getVendors,
  processPdf,
  pushToZoho,
} from "../../services/Atlas";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { notify } from "../../utils/notify";

export default function Home() {
  const [file, setFile] = useState(null);
  const [fileBase64, setFileBase64] = useState("");
  const [fileName, setFileName] = useState("");
  const [vendors, setVendors] = useState([]);
  const [poNumber, setPoNumber] = useState("");
  const [vendorName, setVendorName] = useState("");
  const [vendorBranch, setVendorBranch] = useState("");
  const [vendorBranchID, setVendorBranchID] = useState("");
  const [purchaseOrders, setPurchaseOrders] = useState([]);
  const [jsonData, setJsonData] = useState({ line_items: [] });
  const [editIndex, setEditIndex] = useState({ row: null, col: null });
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [showProcessButton, setShowProcessButton] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isEditingEnabled, setIsEditingEnabled] = useState(false); // New state for enabling/disabling editing

  useEffect(() => {
    try {
      const fetchVendors = async () => {
        const res = await getVendors();
        if (res) {
          setVendors(res);
        }
      };
      fetchVendors();
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    try {
      const fetchPurchaseOrders = async () => {
        const res = await getPurchaseOrders(vendorName);
        if (res) {
          setPurchaseOrders(res);
        }
      };
      vendorName && fetchPurchaseOrders();
    } catch (error) {
      console.error(error);
    }
  }, [vendorName]);

  useEffect(() => {
    if (vendorName && purchaseOrders.length > 0 && !poNumber) {
      setPoNumber(purchaseOrders[0].purchase_order_number);
    }
  }, [vendorName, purchaseOrders, poNumber]);

  const onDrop = (acceptedFiles) => {
    const uploadedFile = acceptedFiles[0];

    if (uploadedFile && uploadedFile.type !== "application/pdf") {
      notify("warning", "Please upload a valid PDF file.");
      return;
    }

    // Clear previous data
    setFile(null);
    setFileBase64("");
    setJsonData({ line_items: [] });
    setEditIndex({ row: null, col: null });
    setShowProcessButton(false);
    setSelectedRows([]);
    setFileName("");

    setFile(URL.createObjectURL(uploadedFile));
    setFileName(uploadedFile.name);
    convertFileToBase64(uploadedFile);
    setShowProcessButton(true);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ".pdf",
  });

  const convertFileToBase64 = async (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      const base64data = reader.result;
      const cleanBase64 = base64data.split(",")[1];
      setFileBase64(cleanBase64);
    };
  };

  const handleInputChange = (index, field, value) => {
    const updatedData = { ...jsonData };
    updatedData.line_items[index][field] = value;
    setJsonData(updatedData);
  };

  const processPdfFile = async () => {
    // Validate if all dropdowns are filled
    if (!vendorName) {
      notify("warning", "Please select a Vendor Name before processing.");
      return;
    }
    if (!vendorBranchID) {
      notify("warning", "Please select a Vendor Branch before processing.");
      return;
    }
    if (!poNumber) {
      notify("warning", "Please select a Purchase Order before processing.");
      return;
    }
    try {
      setLoading(true);
      const response = await processPdf({
        base64: fileBase64,
        vendor_id: vendorName,
        po_id: poNumber,
        name: "test",
      });
      if (Array.isArray(response.line_items)) {
        response.line_items = response.line_items.filter(
          (item) => item.product_code !== ""
        );
      }
      setJsonData(response);
      notify("success", "PDF has been successfully processed.");
      setShowProcessButton(false);
    } catch (error) {
      setShowProcessButton(true);
      console.error("Error processing PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  const uploadToZoho = async () => {
    try {
      setSubmitLoading(true);
      const response = await pushToZoho({
        vendor_id: vendorName,
        vendor_branch_id: vendorBranchID,
        po_id: poNumber,
        extracted_data: jsonData,
      });

      if (response.data.success) {
        notify("success", "Synched to Zoho");
      }
    } catch (error) {
      console.error("Error processing PDF:", error);
    } finally {
      // Reset all states
      setSubmitLoading(false);
      setFile(null);
      setFileBase64("");
      setPoNumber("");
      setVendorName("");
      setVendorBranch("");
      setVendorBranchID("");
      setPurchaseOrders([]);
      setJsonData({ line_items: [] });
      setEditIndex({ row: null, col: null });
      setShowProcessButton(false);
      setSelectedRows([]);
      setFileName("");
    }
  };

  const addRow = () => {
    const emptyRow = {};
    Object.keys(jsonData.line_items[0] || {}).forEach((key) => {
      emptyRow[key] = "";
    });
    jsonData.line_items = [...jsonData.line_items, emptyRow];
    setJsonData({ ...jsonData });
  };

  const deleteSelectedRows = () => {
    const updatedData = { ...jsonData };

    updatedData.line_items = updatedData.line_items.filter(
      (_, index) => !selectedRows.includes(index)
    );
    setJsonData(updatedData);
    setSelectedRows([]);
  };

  const toggleRowSelection = (index) => {
    if (selectedRows.includes(index)) {
      setSelectedRows(selectedRows.filter((i) => i !== index));
    } else {
      setSelectedRows([...selectedRows, index]);
    }
  };

  return (
    <Card className="p-4 md:p-6">
      <Typography variant="h5" color="black">
        Invoice Automation
      </Typography>

      <div className="flex items-center dire flex-row gap-5">
        <div className="my-4">
          <Typography variant="h6" color="black">
            Select Vendor Name
          </Typography>
          <select
            value={vendorName}
            onChange={(e) => {
              setVendorName(e.target.value);
              const selectedVendor = vendors?.find(
                (vendor) => vendor.vendor_id === e.target.value
              );
              if (selectedVendor) {
                setVendorBranch(
                  selectedVendor.vendor_branches[0]?.zc_display_value
                );
                setPoNumber(null);
                setVendorBranchID(selectedVendor.vendor_branches[0]?.ID);
              }
            }}
            className="mt-2 w-64 border border-gray-300 p-2 rounded cursor-pointer"
          >
            <option value="" disabled>
              Select a vendor
            </option>
            {vendors?.map((vendor) => (
              <option key={vendor.vendor_id} value={vendor.vendor_id}>
                {vendor.vendor_name}
              </option>
            ))}
          </select>
        </div>

        {vendorName && (
          <div className="my-4">
            <Typography variant="h6" color="black">
              Vendor Branch
            </Typography>
            <select
              value={vendorBranchID}
              onChange={(e) => {
                const branch = vendors
                  ?.find((vendor) => vendor.vendor_id === vendorName)
                  .vendor_branches?.find(
                    (branch) => e.target.value === branch.ID
                  );
                setVendorBranch(branch?.zc_display_value);
                setVendorBranchID(e.target.value);
              }}
              className="mt-2 w-64 border border-gray-300 p-2 rounded cursor-pointer"
            >
              {vendors
                ?.find((vendor) => vendor.vendor_id === vendorName)
                .vendor_branches?.map((branch) => (
                  <option key={branch?.ID} value={branch?.ID}>
                    {branch?.zc_display_value}
                  </option>
                ))}
            </select>
          </div>
        )}

        {vendorName && purchaseOrders.length > 0 && (
          <div className="my-4">
            <Typography variant="h6" color="black">
              Purchase Orders
            </Typography>
            <select
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
              className="mt-2 w-64 border border-gray-300 p-2 rounded cursor-pointer"
            >
              {purchaseOrders?.map((order) => (
                <option
                  key={order.purchase_order_number}
                  value={order.purchase_order_number}
                >
                  {order.purchase_order_number}
                </option>
              ))}
            </select>
          </div>
        )}
      </div>

      <div
        {...getRootProps()}
        className="border-2 border-dashed border-gray-400 p-4 my-4 text-center cursor-pointer"
      >
        <input {...getInputProps()} />
        <Typography variant="small">
          Drag and drop a PDF or image file here, or click to select one
        </Typography>
      </div>

      {file && showProcessButton && (
        <div className="flex justify-center my-4">
          <Button disabled={loading} onClick={processPdfFile} loading={loading}>
            {loading ? "Processing.." : "Process PDF"}
          </Button>
        </div>
      )}

      {fileName && (
        <div className="my-4">
          <Typography variant="h6" color="black">
            Uploaded File: {fileName}
          </Typography>
        </div>
      )}

      {file && (
        <CardBody>
          <Typography variant="h6" color="black">
            Invoice Preview
          </Typography>
          <div
            className="pdf-viewer-container"
            style={{
              display: "flex",
              justifyContent: "center",
              height: "700px",
              overflowY: "hidden",
              border: "1px solid #ddd",
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            <Viewer fileUrl={file} />;
          </div>
        </CardBody>
      )}

      {loading && (
        <div className="flex justify-center my-4 items-center">
          Processing please wait .. <Spinner className="h-12 w-12" />
        </div>
      )}

      {jsonData.line_items?.length > 0 && (
        <CardBody>
          <Typography variant="h6" color="black">
            Invoice Data
          </Typography>
          <div className="my-4">
            <Switch
              checked={isEditingEnabled}
              onChange={() => setIsEditingEnabled(!isEditingEnabled)}
              label="Enable/Disable Editing"
              className="mb-4"
            />
          </div>
          <div className="flex justify-end mb-2">
            <Button
              onClick={addRow}
              className="mr-2"
              disabled={!isEditingEnabled}
            >
              Add Row
            </Button>
            <Button onClick={deleteSelectedRows} disabled={!isEditingEnabled}>
              Delete Selected Rows
            </Button>
          </div>
          <div className="overflow-x-auto overflow-y-auto max-h-96">
            <table className="min-w-full bg-white border">
              <thead>
                <tr>
                  <th className="py-1 px-2 border-b border-gray-200 bg-gray-100 text-left sticky top-0 bg-white">
                    Select
                  </th>
                  {Object.keys(jsonData.line_items[0])?.map((header) => (
                    <th
                      key={header}
                      className="py-1 px-2 border-b border-gray-200 bg-gray-100 text-left sticky top-0 bg-white"
                    >
                      {header.charAt(0).toUpperCase() + header.slice(1)}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {jsonData.line_items.map((item, rowIndex) => (
                  <tr key={rowIndex}>
                    <td className="py-1 px-2 border-b border-gray-200 text-center">
                      <input
                        type="checkbox"
                        checked={selectedRows.includes(rowIndex)}
                        onChange={() => toggleRowSelection(rowIndex)}
                      />
                    </td>
                    {Object.keys(item)?.map((field, colIndex) => (
                      <td
                        key={field}
                        className="py-1 px-2 border-b border-gray-200"
                        onClick={(e) => {
                          if (isEditingEnabled) {
                            setEditIndex({ row: rowIndex, col: colIndex });
                            e.currentTarget.style.outline = "none";
                          }
                        }}
                        style={{
                          cursor: isEditingEnabled ? "pointer" : "not-allowed",
                          outline: "none",
                        }}
                        onMouseEnter={(e) => {
                          if (
                            !(
                              editIndex.row === rowIndex &&
                              editIndex.col === colIndex
                            )
                          ) {
                            e.currentTarget.style.outline = isEditingEnabled
                              ? "2px solid #1E201E"
                              : "none";
                          }
                        }}
                        onMouseLeave={(e) => {
                          if (
                            !(
                              editIndex.row === rowIndex &&
                              editIndex.col === colIndex
                            )
                          ) {
                            e.currentTarget.style.outline = "none";
                          }
                        }}
                      >
                        {editIndex.row === rowIndex &&
                        editIndex.col === colIndex &&
                        isEditingEnabled ? (
                          <input
                            type="text"
                            value={item[field] || ""}
                            onChange={(e) =>
                              handleInputChange(rowIndex, field, e.target.value)
                            }
                            className="border-2 border-gray-200 p-1"
                            style={{
                              width: `${Math.max(
                                (item[field] || "").length * 8,
                                100
                              )}px`,
                            }}
                            autoFocus
                            onBlur={() =>
                              setEditIndex({ row: null, col: null })
                            }
                          />
                        ) : (
                          item[field] || ""
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <Button
            loading={submitLoading}
            className="bg-navColor mt-3"
            onClick={uploadToZoho}
          >
            Sync to Zoho
          </Button>
        </CardBody>
      )}
    </Card>
  );
}
